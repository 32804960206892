import React, { useRef, useEffect, useState} from 'react';
import "../YTFullCoursePage.css";

const YTFullCoursePage = () => {
  const videoRefs = useRef({});

  const [playingVideoId, setPlayingVideoId] = useState(null);

  useEffect(() => {
    // Load the IFrame Player API asynchronously
    const tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // Assign the API ready function to the global window object
    window.onYouTubeIframeAPIReady = () => {
      Object.keys(videoRefs.current).forEach(id => {
        const iframe = videoRefs.current[id];
        videoRefs.current[id] = new window.YT.Player(iframe, {
          events: {
            onReady: onPlayerReady
          }
        });
      });
    };
  }, []);

  const onPlayerReady = (event) => {
    const player = event.target;
    videoRefs.current[player.getIframe().id] = player;
  };

  const togglePlayPause = (id) => {
    const player = videoRefs.current[id];
    const button = document.querySelector(`[data-video-id="${id}"]`);
  
    if (player && button) {
      const playerState = player.getPlayerState();
  
      if (playerState === window.YT.PlayerState.PLAYING) {
        player.pauseVideo();
        button.setAttribute("data-state", "play");
      } else {
        player.playVideo();
        button.setAttribute("data-state", "pause");
      }
    }
  };

  const courseData = {
    cloudComputing: [
      { id: 1, videoUrl: "https://www.youtube.com/embed/sVlo7W3iDDQ", name: "DevOps - Basics to Advanced",
        description: "Learn how to speed up software development and operations with DevOps. Perfect for beginners and beyond!"
      },
      { id: 2, videoUrl: "https://www.youtube.com/embed/6ZGtOe---X8", name: "AWS - Basics to Advanced",
        description: "Discover how to use Amazon Web Services to build and manage apps in the cloud, from start to finish."
      },
      { id: 3, videoUrl: "https://www.youtube.com/embed/1ciWsHm-fS4", name: "Azure - Basics to Advanced",
        description: "Get started with Microsoft Azure and learn how to deploy and manage cloud apps easily"
      },
      { id: 4, videoUrl: "https://www.youtube.com/embed/vAy7rhoBa_E", name: "Docker - Basics to Advanced",
        description: "See how Docker makes apps easy to deploy and move, from basics to more advanced topics."
      },
      { id: 5, videoUrl: "https://www.youtube.com/embed/zSrVfT_W7PQ", name: "Kubernetes - Basics to Advanced",
        description: "Master Kubernetes and learn how to manage your app containers automatically."
      },
    ],
    webDevelopment: [
      { id: 6, videoUrl: "https://www.youtube.com/embed/y6fzqBsi1-Y", name: "HTML and CSS - Basics to Advanced",
        description: "Create beautiful websites by learning HTML and CSS, from the basics to advanced designs."
      },
      { id: 7, videoUrl: "https://www.youtube.com/embed/ULzUzc0gMfI", name: "JavaScript - Basics to Advanced",
        description: "Make your web pages interactive with JavaScript, starting from the basics to advanced features."
      },
      { id: 8, videoUrl: "https://www.youtube.com/embed/-tzG0bjZV7A", name: "React JS - Basics to Advanced",
        description: "Build dynamic web apps with React, a powerful tool for creating interactive user interfaces."
      },
    ],
    dataScience: [
      { id: 9, videoUrl: "https://www.youtube.com/embed/AsghfLOROuY", name: "Data Analytics - Basics to Advanced",
        description: "Learn to analyze data effectively, starting from the basics and moving to advanced techniques."
      },
      { id: 10, videoUrl: "https://www.youtube.com/embed/exqFMNJKDOE", name: "MySQL - Basics to Advanced",
        description: "Manage databases with MySQL, from beginner level to advanced database operations."
      },
      { id: 11, videoUrl: "https://www.youtube.com/embed/Jlm-UUjWgcw", name: "Power BI - Basics to Advanced",
        description: "Learn Power BI basics and how to use it to create useful visualizations and reports for better decisions."
      },
      { id: 12, videoUrl: "https://www.youtube.com/embed/KpiSRshpIy0", name: "AI and ML - Basics to Advanced",
        description: "Explore the basics of AI and Machine Learning, and learn how to apply them to real-world problems."
      },
    ],
    softwareTesting: [
      { id: 13, videoUrl: "https://www.youtube.com/embed/XI6AcMG4inw", name: "Manual Testing - Basics to Advanced",
        description: "Ensure software quality with manual testing, covering everything from the basics to advanced methods."
      },
      { id: 14, videoUrl: "https://www.youtube.com/embed/qqaYA6Erbb8", name: "Selenium Testing - Basics to Advanced",
        description: "Automate web testing with Selenium, from getting started to more advanced techniques."
      },
    ],
    programmingLanguages: [
      { id: 15, videoUrl: "https://www.youtube.com/embed/i5B9JPq3MbI", name: "Python - Basics to Advanced",
        description: "Start coding with Python, an easy-to-learn language that’s great for beginners and experts alike."
      },
      { id: 16, videoUrl: "https://www.youtube.com/embed/2w2ggu8vUc0", name: "Java - Basics to Advanced",
        description: "Learn Java programming, a popular language for building all kinds of applications."
      },
    ],
    others: [
      { id: 17, videoUrl: "https://www.youtube.com/embed/jaodrLJle-M", name: "PHP - Basics to Advanced",
        description: "Create dynamic websites with PHP, starting from the basics to advanced development."
      },
      { id: 18, videoUrl: "https://www.youtube.com/embed/q_VmwX8nGcg", name: "Networking - Basics to Advanced",
        description: "Understand how networks work, from basic concepts to more advanced networking skills."
      },
      { id: 19, videoUrl: "https://www.youtube.com/embed/oB6koGfNSg8",name: "Linux - Basics to Advanced",
        description: "Explore Linux basics and advanced skills. Perfect for learning how to use and manage this OS."
      },
      { id: 20, videoUrl: "https://www.youtube.com/embed/kBtq6XjCE7s",name: "Aptitude - Basics to Advanced",
        description: "Improve your problem-solving skills with this guide to mastering aptitude tests."
      },
    ],
  };

  const renderSection = (title, videos) => (
    <div className="section">
      <h2 className="section-title">{title}</h2>
      <div className="video-container">
        {videos.map((video) => (
          <div key={video.id} className="video-card">
            <iframe
              ref={(el) => (videoRefs.current[`video-${video.id}`] = el)}
              id={`video-${video.id}`}
              title={`Video ${video.id}`}
              width="100%"
              height="220"
              src={video.videoUrl + "?enablejsapi=1"}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              loading="lazy"
            ></iframe>
            <div className="video-content">
              <p className="video-name">{video.name}</p>
              <p className="video-description">{video.description}</p>
              <button 
                className="watch-button" 
                data-video-id={`video-${video.id}`}
                onClick={() => togglePlayPause(`video-${video.id}`)}
              >
                Watch
              </button>
            </div>
          </div>
        ))}
      </div>
      <hr className="section-divider" />
    </div>
  );

  return (
    <div>
      <h1 className="yt-page-title">Free Courses</h1>
      {renderSection("Cloud Computing", courseData.cloudComputing)}
      {renderSection("Web Development", courseData.webDevelopment)}
      {renderSection("Data Science", courseData.dataScience)}
      {renderSection("Software Testing & QA", courseData.softwareTesting)}
      {renderSection("Programming Languages", courseData.programmingLanguages)}
      {renderSection("Others", courseData.others)}
    </div>
  );
};

export default YTFullCoursePage;
